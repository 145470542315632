import React, {useEffect, useState} from "react";
import {
    Form,
    Row,
    Col,
    Button,
    Card,
    notification,
    DatePicker,
    Select,
    Divider,
    Input,
    InputNumber,
    Table,
    Upload,
    message,
    Checkbox
} from 'antd';
import jsonfile from './collection.json';
import jsonMemberFile from '../../member/member.json';
import {CisUI} from '../../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import $ from "jquery";
import InputOnly from "../../../../../util/InputOnly";
import moment from "moment";
import dayjs from 'dayjs';
import 'moment/locale/es-us';

import IntlMessages from "../../../../../util/IntlMessages";
import SelectData from "../../../../../util/selectdata";
import CircularProgress from "../../../../../components/CircularProgress";
import {UploadOutlined} from "@ant-design/icons";

const {Option} = Select;
const NewMonthlyCollection = (props) => {
    moment.locale('en-us');
    const userArea = CisUI().getUserInfo('comarea');
    const [loading, setLoading] = useState(false);
    const[received, setReceived] = useState([]);

    const [form] = Form.useForm();
    const [cashRow, setCashRow] = useState([]);
    const [showMissionOffering, setShowMissionOffering] = useState(true);
    const [expenseRow, setExpenseRow] = useState([]);
    const [totalCash, setTotalCash] = useState(0.00);
    const [totalCashReceived, setTotalCashReceived] = useState(0.00);
    const [totalExpense, setTotalExpense] = useState(0.00);
    const [totalExpenseCollect, setTotalExpenseCollect] = useState(0.00);
    const [totalMissionOffering, setTotalMissionOffering] = useState(0.00);
    const [totalMissionCollection, setTotalMissionCollection] = useState(0.00);
    const [collectionDisabled, setCollectionDisabled] = useState(true);
    const history = useHistory();
    let locals = SelectData('locallist');
    let donation_type = SelectData('donation/contributiontype_donation');
    // let expenses = SelectData('donation/expensetype?area=6');
    
    // useEffect(() => {
    //     getExpenses()
    // }, [])
    // const getExpenses = () => {
    //     setLoading(true)
    //     axios.get('donation/expensetype?area=6')
    //         .then(res => {
    //             if(res.data.status === 1){
    //                 let expenseData = [];
    //                 res.data.data.map((item, index) => {
    //                     expenseData.push({
    //                         'account_name': item.name,
    //                         'account_id': item.id,
    //                         'amount': 0.00,
    //                         'remarks': "",
    //                         'attachment': []
    //
    //                     })
    //                 })
    //                 setExpenseRow(expenseData)
    //             }
    //             setLoading(false)
    //         })
    //         .catch(error => {
    //             setLoading(false)
    //             console.log(error)
    //         })
    // }
    

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../" + jsonfile.urls.list;
    

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : " + props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
    }


    const onFinish = (values) => {
        // setLoading(true)
        // if(totalCash === 0){
        //     notification.warning({
        //         message: 'Alert',
        //         type: "warning",
        //         description: "Please enter at-least one week cash received amount."
        //     });
        // }
        // console.log("values", values);
        // return;
        if (!values.no_mission_offering){
            if (values['mission_collection_amount'] === 0) {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Mission offering can not ber zero."
                });
                return;
            }
        }
        setLoading(true)
        let data = {
            ...values,
            month: values['month'].format('YYYY-MM'),
            // entry_date: values['entry_date'].format('YYYY-MM-DD'),
            cash_received: cashRow,
            expenses: expenseRow
        }
        
        console.log(data)
        // return;

        axios.post(Config.apiserver + 'donation/newmonthlycollection', data)
            .then(res => {
                console.log(res.data)
                if (res.data.status === 1) {
                    notification.warning({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push('/'+jsonfile.urls.list)
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading(false)
                // setLoading({loading: false});
                // document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                setLoading(false)
                // msg = (errors);
                // setLoading({loading: false});
                //setResponse([]);
                // document.getElementById("loader").style.display = "none";
            });
    };
    

    if (CisUI().getUserInfo('comarea') === 6) {
        const titheID = localStorage.getItem("titheID") || null;
        //jsonfile.input[2].value = parseInt(titheID);
    }

    //console.log("JSON Data : "+JSON.stringify(jsonfile.input));

    // const onMonthSelect = (e) => {
    //     let startOfMonth = moment(e).startOf('month')
    //     let endOfMonth   = moment(e).endOf('month');
    //     let firstSunday = moment(e).date(1).weekday(7);
    //     const totalDays = moment(e).daysInMonth();
    //     let startDate = firstSunday;
    //     console.log(startDate, endOfMonth)
    //     let rowData = [
    //         {
    //             'title': '1st Week Collection',
    //             'tranx_date': firstSunday.format('YYYY-MM-DD'),
    //             'day': firstSunday.format('dddd'),
    //             'amount': 0.00
    //         }
    //     ]
    //     let weekNo = ['2nd', '3rd', '4th', '5th'];
    //     let weekCount = 0;
    //
    //     while (startDate.format('YYYY-MM-DD') < endOfMonth.format('YYYY-MM-DD')){
    //         let newDate = startDate.add(7, 'days')
    //         if(newDate.month() === endOfMonth.month()) {
    //             rowData.push({
    //                 'title': weekNo[weekCount] + ' Week Collection',
    //                 'tranx_date': newDate.format('YYYY-MM-DD'),
    //                 'day': newDate.format('dddd'),
    //                 'amount': 0.00
    //             })
    //             weekCount += 1;
    //         }
    //         startDate = newDate
    //     }
    //
    //     setCashRow(rowData)
    //
    //     console.log("sundays", rowData);
    // }
    
    const cash_column = [
        {
            title: <p style={{marginLeft: "15px"}}><IntlMessages id="Week" /></p>,
            dataIndex: "title",
            key: "title",
            render: (value, row, index) => <p style={{ marginLeft: "20px", fontSize: "14px" }}>{value}</p>
        },
        // {
        //     title: <p style={{marginLeft: "15px"}}><IntlMessages id="Day"/></p>,
        //     dataIndex: "day",
        //     key: "day",
        //     render: (value, row, index) => <p style={{ marginLeft: "20px", fontSize: "14px" }}>{value}</p>
        // },
        {
            title: <p style={{marginLeft: "15px"}}><IntlMessages id="Transaction Date"/></p>,
            dataIndex: "tranx_date",
            key: "tranx_date",
            render: (value, row, index) => <p style={{ marginLeft: "20px", fontSize: "14px" }}>{value}</p>
        },
        {
            title: <p style={{marginLeft: "15px"}}><IntlMessages id="Date Range"/></p>,
            dataIndex: "collection_range",
            key: "collection_range",
            render: (value, row, index) => <p style={{ marginLeft: "20px", fontSize: "14px" }}>{value}</p>
        },
        {
            title: <p style={{marginLeft: "15px"}}><IntlMessages id="Already Collected"/></p>,
            dataIndex: "already_collection",
            key: "already_collection",
            align: "right",
            render: (value, row, index) => <p style={{ marginRight: "20px", fontSize: "14px" }}>{CisUI().getCurrencyFormated1(value)}</p>
        },
        {
            title: <p style={{margin: "0 15px"}}><IntlMessages id="Amount"/></p>,
            dataIndex: "amount",
            key: "amount",
            width: "200px",
            align: "right",
            render: (value, row, index) =>
                <p style={{ margin: "5px 20px" }}>
                    <InputNumber
                        // value={0.00}
                        placeholder="Amount"
                        className="cash_input_field gx-w-100"
                        min={0}
                        onChange={(e) => handleCashAmount(e, 'amount', index)}
                        
                    />
                </p>
        }
    ];
    
    const expense_column = [
        {
            title: <p style={{marginLeft: "15px"}}><IntlMessages id="Account name" /></p>,
            dataIndex: "account_name",
            key: "account_name",
            render: (value, row, index) => <p style={{ marginLeft: "20px", fontSize: "14px" }}>{value}</p>
        },
        {
            title: <p style={{margin: "0 15px"}}><IntlMessages id="Already Collected"/></p>,
            dataIndex: "already_collection",
            key: "already_collection",
            width: "200px",
            align: "right",
            render: (value, row, index) =>
                <p style={{margin: "5px 20px", fontSize: "14px"}}>
                    {CisUI().getCurrencyFormated1(value)}
                </p>
        },
        {
            title: <p style={{margin: "0 15px"}}><IntlMessages id="Amount"/></p>,
            dataIndex: "amount",
            key: "amount",
            width: "200px",
            align: "right",
            render: (value, row, index) =>
                <p style={{margin: "5px 20px"}}>
                    <InputNumber
                        // value={value}
                        min={0}
                        placeholder="Amount"
                        className="cash_input_field gx-w-100"
                        onChange={(e) => handleExpense(e, 'amount', index)}
                        controls={false}
                    />
                </p>
        },
        {
            title: <p style={{marginLeft: "15px"}}><IntlMessages id="Remarks"/></p>,
            dataIndex: "remarks",
            key: "remarks",
            render: (value, row, index) =>
                <p style={{margin: "0 20px", fontSize: "14px"}}>
                    <Input.TextArea placeholder="Remarks" onChange={(e) => handleExpense(e, 'remarks', index)}/>
                </p>
        },
        {
            title: <p style={{margin: "0 15px"}}><IntlMessages id="Attachment"/></p>,
            dataIndex: "attachment",
            key: "attachment",
            render: (value, row, index) =>
                <p style={{margin: "5px 20px"}}>
                    {
                        row.attachments.length > 0 && row.attachments.map((item, ind) =>
                            <a href={Config.attachement + item.location} target="_blank" style={{ marginRight: "10px" }}><i className="fa fa-file"></i></a>
                        )
                        
                    }
                    {/*<Button type="button"*/}
                    {/*        className="ant-btn ant-btn-primary btn btn-primary btn-circle ant-popover-open"*/}
                    {/*        >*/}
                    {/*</Button>*/}
                    <Upload
                        onRemove={(e) => deleteImage(e, index)}
                        beforeUpload={(e) => beforeUpload(e, index)}
                        onChange={(e) => handleExpense(e, "attachment", index)}
                    >
                        <Button
                            icon={<UploadOutlined style={{margin: '0 5px'}}/>}
                            style={{margin: "0"}}
                        >
                            Select File
                        </Button>
                    </Upload>
                </p>
        }
    ];
    
    const handleCashAmount = (e, name, index) => {
        console.log(e)
        console.log(name)
        console.log(index)
        let total = 0;
        const list = [...cashRow];
        list[index][name] = e
        list.map((item, index) => total += parseFloat(item.amount))
        setCashRow(list)
        setTotalCash(total.toFixed(2))
    }
    
    const beforeUpload = (event) => {
        const isJpgOrPng =
            event.type === "image/jpg" ||
            event.type === "image/jpeg" ||
            event.type === "image/png" ||
            event.type === 'application/vnd.ms-excel' ||
            event.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            event.type === "application/pdf";
        
        if (!isJpgOrPng) {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "You can only upload jpg, jpeg and pdf file only!"
            });
            return false;
        }
        if (event.size / 1024 / 1024 > 1) {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "File size must be less than 1MB!"
            });
            return false;
        }
        
        return false;
    };
    
    const handleExpense = (e, name, index) => {
        let list = [...expenseRow];
        let total = 0.00;
        if (name === "amount") {
            list[index][name] = e;
        } else if (name === "attachment") {
            console.log('name', e['file'].status)
            if (e["file"].status === undefined) {
                
                console.log("file", e.file.uid)
                
                let fileData = [];
                let file = e.file;
                const reader = new FileReader();
                // console.log(reader)
                reader.onload = (ene) => {
                    console.log(ene)
                    list[index][name] = [
                        ...list[index]["attachment"],
                        {
                            "id": e.file.uid,
                            "file": ene.target.result
                        },
                    ];
                    // axios.post(Config.apiserver + 'testupload', {'image': ene.target.result})
                    //     .then(res => {
                    //       console.log(res.data)
                    //     })
                    fileData.push(ene.target.result);
                };
                reader.readAsDataURL(file);
                // console.log("fileData", fileData);
                
            }
        }
        else if(name === 'remarks'){
            list[index][name] = e.target.value
        }
        
        list.map((item, index) => total += parseFloat(item.amount))
        console.log("list", list)
        setExpenseRow(list)
        setTotalExpense(total.toFixed(2))
    };
    
    
    const deleteImage = (e, idx) => {
        const list = [...expenseRow];
        let index = "";
        console.log("delete", e)
        console.log("delete", idx)
        list[idx]["attachment"].find((item, i) => {
            console.log("delete", item)
            console.log("delete", i)
            if (item.uid === e.uid) {
                index = i;
            }
        });
        list[idx]["attachment"].splice(index, 1);
        setExpenseRow(list);
    };
    
    const getMonthlyCollections = () => {
        let month = form.getFieldValue('month');
        let local_id = form.getFieldValue('local_id');
        let account_id = form.getFieldValue('head_id');
        
        if (userArea !== 6) {
            if (month === undefined || month === null || local_id === undefined || local_id === null) {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Please select month and local."
                });
                return;
                // message.warn('Please select month and local_id')
            }
        }
        else{
            if (month === undefined || month === null) {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Please select month."
                });
                return;
                // message.warn('Please select month and local_id')
            }
        }
        setLoading(true)
        axios.get(`donation/getmonthlycollections?month=${moment(month).format('YYYY-MM')}&local_id=${local_id}&account_id=${account_id}`)
            .then(res => {
                console.log("cashreceived", res.data)
                if(res.data.status === 1){
                    setCashRow(res?.data?.cash_received)
                    setExpenseRow(res?.data?.expenses)
                    setTotalCashReceived(res?.data?.total_cash_received)
                    setTotalExpenseCollect(res?.data?.total_expense)
                    setTotalMissionOffering(res?.data?.total_mission_offering)
                    form.setFieldsValue({
                        total_mission_offering: res?.data?.total_mission_offering
                    })
                    setTotalMissionCollection(res?.data?.total_mission_offering)
                    setCollectionDisabled(false)
                }
                setLoading(false)
            })
            .catch(errors => console.log(errors))
    }
    
    const handleMissionOffering = (e) => {
        // console.log(e)
        let amount = 0;
        if(e){
            amount = e;
        }
        let total = parseFloat(totalMissionOffering) + parseFloat(amount)
        setTotalMissionCollection(total)
    }
    
    // if(loading){
    //     return <CircularProgress />;
    // }
    
    console.log("cashreceived", received)

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction("../../" + jsonfile.urls.list)
                : CisUI().addAction("../" + jsonfile.urls.list)
        }>
            {/*{CisUI().showLoading}*/}
            {
                loading ? <CircularProgress />
                :

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                // className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            id="month"
                            label="Tithes Closing Month"
                            name="month"
                            // initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Month is required"
                                },
                            ]}
                        >
                            <DatePicker picker="month"
                                        format={"MMMM YYYY"}
                                        // onChange={onMonthSelect}
                                        className="gx-mb-3 gx-w-100"
                                        placeholder="Transaction Closing month"/>
                        
                        </Form.Item>
                    </Col>
                    {/*<Col lg={8} md={8} sm={24} xs={24}>*/}
                    {/*    <Form.Item*/}
                    {/*        id="entry_date"*/}
                    {/*        label="Entry Date"*/}
                    {/*        name="entry_date"*/}
                    {/*        initialValue={moment()}*/}
                    {/*        rules={[*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: "Transaction date is required"*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*    >*/}
                    {/*        <DatePicker*/}
                    {/*            picker="date" format={"YYYY-MM-DD"}*/}
                    {/*            // disabledDate={(current) => {*/}
                    {/*            //     return moment(cdate.start) >= current || moment(cdate.end) <= current;*/}
                    {/*            // }}*/}
                    {/*            className="gx-mb-3 gx-w-100"*/}
                    {/*            placeholder="Transaction date"/>*/}
                    {/*    */}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            id="head_id"
                            label="Contribution Type"
                            name="head_id"
                            rules={[
                                {
                                    required: true,
                                    message: "Contribution Type is required"
                                },
                            ]}
                            initialValue={19723}
                        >
                            <Select
                                showSearch
                                placeholder="Select an option"
                                optionFilterProp="children"
                                // onChange={onBankChange}
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                disabled={true}
                            >
                                {
                                    donation_type.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        
                        </Form.Item>
                    </Col>
                    {
                        userArea !== 6 &&
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                                id="local_id"
                                label="Local Assembly"
                                name="local_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Local Assembly is required"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Local Assembly"
                                    optionFilterProp="children"
                                    // onChange={onBankChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        locals.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            
                            </Form.Item>
                        </Col>
                    }
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            id="mission_offering"
                            label="Mission Offering"
                            name="mission_offering"
                            rules={[
                                {
                                    required: true,
                                    message: "Mission Offering is required"
                                },
                            ]}
                            initialValue={19724}
                        >
                            <Select
                                showSearch
                                placeholder="Select an option"
                                optionFilterProp="children"
                                // onChange={onBankChange}
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                disabled={true}
                            >
                                {
                                    donation_type.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        
                        </Form.Item>
                    </Col>
                    {
                        totalMissionOffering > 0 &&
                        
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                                id="total_mission_offering"
                                label="Mission Offering Collected"
                                name="total_mission_offering"
                                rules={[
                                    {
                                        required: false,
                                        message: "Mission Offering is required"
                                    },
                                ]}
                            >
                                <InputNumber className="gx-w-100" min={0} placeholder="Mission Offering Collection" disabled={true}/>
                            </Form.Item>
                        </Col>
                    }
                    
                    
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            id="mission_collection_amount"
                            label="Mission Offering Amount"
                            name="mission_collection_amount"
                            rules={[
                                {
                                    required: showMissionOffering,
                                    message: "Mission Offering is required"
                                },
                            ]}
                            initialValue={0}
                        >
                            <InputNumber
                                className="gx-w-100"
                                min={0}
                                placeholder="Mission Offering Amount"
                                onChange={handleMissionOffering}
                                disabled={collectionDisabled}
                            />
                        </Form.Item>
                        
                        <p style={{ marginLeft: "15px", color: "#072F4C" }}>Total Collected: {totalMissionCollection}</p>
                    </Col>

                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            id="no_mission_offering"
                            label="No Mission Offering"
                            name="no_mission_offering"
                            rules={[
                                {
                                    required: false,
                                    message: "Mission Offering is required"
                                },
                            ]}
                            valuePropName="checked"
                        >
                            <Checkbox onClick={(e) => setShowMissionOffering(!e.target.checked)}>Yes</Checkbox>
                        </Form.Item>
                    </Col>
                    
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <Button type="primary" onClick={getMonthlyCollections} style={{ margin: "22px 0 0 15px"}}>
                            Next
                        </Button>
                    </Col>
                    {
                        cashRow.length > 0 &&
                        <>
                            <Divider orientation={`left`} style={{ color: "#273947" }}>Cash Received</Divider>
                            <Col lg={24} md={24} sm={24} xs={24}>
                        
                                <Table
                                    bordered={true}
                                    // className={`ptable`}
                                    columns={cash_column}
                                    dataSource={cashRow}
                                    // footer={() => cashFooterContent()}
                                    pagination={false}
                                    summary={() => {
                                        return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell index={1} colSpan={3}>
                                                            <p style={{ textAlign: "right", fontWeight: "bold", color: "#273947" }}>Total</p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={2}>
                                                            <p style={{textAlign: "right", fontWeight: "bold", color: "#273947"}}>{ totalCashReceived }</p>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={3}>
                                                            <p style={{textAlign: "right", fontWeight: "bold", color: "#273947"}}>{totalCash}</p>
                                                        </Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                </>
                                            )
                                        }
                                    }
                                />
                                {/*:*/}
                                {/*<p style={{ color: "#001d36", textAlign: "center", fontSize: "17px" }}>*** Please Select Tithe Closing Month ***</p>*/}
                        
                            
                            </Col>
                        </>
                    }
                    {
                        expenseRow.length > 0 &&
                        <>
                            <Divider orientation={`left`} style={{ color: "#273947" }}>Deduct Local Expense</Divider>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Table
                                    bordered={true}
                                    columns={expense_column}
                                    dataSource={expenseRow}
                                    pagination={false}
                                    summary={() => {
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={1} colSpan={1}>
                                                        <p style={{ textAlign: "right", fontWeight: "bold", color: "#273947" }}>Total</p>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>
                                                        <p style={{textAlign: "right", fontWeight: "bold", color: "#273947"}}>{ totalExpenseCollect }</p>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3}>
                                                        <p style={{textAlign: "right", fontWeight: "bold", color: "#273947"}}>{totalExpense}</p>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        )
                                    }
                                    }
                                />
                            
                            </Col>
                        </>
                    }
                    
                    {
                        cashRow.length > 0 &&
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={24}>
                                <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: 'center', marginTop: "20px"}}>
                                    <Button id="submitbutton" type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                    <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                                </Col>
                            </Row>
                        </Col>
                    }
                    
                </Row>
            </Form>
            }

        </Card>
    );
};

export default NewMonthlyCollection;