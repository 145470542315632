import React, {useState, useEffect} from "react";
import { Form, Col, Input, Collapse ,Card, notification} from 'antd';
import jsonfile from './trialbalance.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import AdvancedSearchBeneficiary from "../../../../util/AdvanceSearchWithBeneficiary";
import localStorage from "react-secure-storage";

const Search = Input.Search;
const Panel = Collapse.Panel;

const TrialBalanceV3 = (props) => {
    const value = 0;
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const accountID = params.get("account_id") || 0;
    const date = params.get("date") || "";
    let url = Config.apiserver+jsonfile.urls.listV3;
    const [dateD,setDateD] = useState("");
    if(accountID > 0) {
        url = Config.apiserver+jsonfile.urls.listV3+"?account_id="+accountID+"&date="+date;
    }

    const [state,setState] = useState({
        reqURL: url,
        filtervalue : '',
        subtitle : '',
    });

    const [account,setAccount] = useState({
        'fromDate' : null,
        'toDate' : null,
    });
    const [hide,setHide] = useState([]);

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data,setData] = useState([]);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);


    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                    setAccount(res.data.account);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    setDateD(dt);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.listV3 + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };


    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearchBeneficiary(jsonfile.search,"",1,'date',CisUI().PeriodFiled(form), form)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }


    const getDetailsLink = (id) => {
        const source = "ledger";
        return "../../accounts/ledger?account_id="+id+"&date="+dateD;
    }

    const ListActionButtonReportXLS = (url="",querystring="",apiURL="",dataParameter="",filename="",fullJson={}, subtitle="",printmode="portrait") =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/coploading.gif" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                {
                    jsonfile.urls.hasOwnProperty("list") ?
                        <>
                            <button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIDataXLS(apiURL,querystring)} title="Export to Excel"><i
                                className="fas fa-file-excel"/></button>
                            <button onClick={(e) => print(apiURL,dataParameter,filename)} className="btn btn-export-print btn-circle-md" title="Print"><i
                                className="fas fa-print"/></button>
                        </>
                        :
                        ""
                }
            </div>
        );

    const print = (apiurl,datakey,filename) => {
        window.open(Config.baseurl+"reports/financial.html?url="+window.btoa(apiurl)+"&filename="+filename+"&data="+datakey+"&title=Trial Balance","_blank");
    }

    const getAPIDataXLS = (APIUrl, qrystring) => {
        let URL  = APIUrl+"/xls?"+qrystring+"&token="+localStorage.getItem("token") || 0;
        if(APIUrl.includes("?")) {
            const sp = APIUrl.split("?");
            URL  = sp[0]+"/xls?"+sp[1]+"&token="+localStorage.getItem("token") || 0;
        }

        console.log("URL :"+URL);
        window.open(URL,"_blank");
    };

    const getExtra = (ListActionButtonReportXLS("../"+jsonfile.urls.add,"",state.reqURL,"data","trial_balance",jsonfile,"Journal", "landscape"));


    return (
        <>
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                {CisUI().showLoading}

                <div className="col-md-12" style={{textAlign: 'center'}}>
                    <h4 style={{textTransform: 'uppercase'}}>{CisUI().getUserInfo('companyName')}</h4>
                    <h3>Trial Balance</h3>
                    <h4>For the period of {CisUI().getFormatedDate(account?.fromDate)} to {CisUI().getFormatedDate(account?.toDate)}</h4>
                </div>
                <hr />

                <div className="table-responsive">
                    <table className="statement table">
                        <thead>
                        <tr>
                            <th>Account/Title</th>
                            <th style={{textAlign : 'right'}}>
                                <div style={{textAlign: 'right'}}>Debit ({CisUI().getCurrencySymbol()})</div>
                            </th>
                            <th style={{textAlign : 'right'}}>
                                <div style={{textAlign: 'right'}}>Credit ({CisUI().getCurrencySymbol()})</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((itmes,index)=> {
                                if(itmes.type === 'section') {
                                    return <tr className="showTotalBorder">
                                        <td colSpan={6} style={{fontWeight: 'bold'}}>
                                            {itmes.name}
                                        </td>
                                    </tr>
                                }
                                else if(itmes.type === 'total') {
                                    return <tr className="showTotalBorder">
                                        <td className="clmintent" style={{fontWeight: 'bold', paddingLeft: '20px'}} >{itmes.name}</td>
                                        <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated3(itmes.debit)}</td>
                                        <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated3(itmes.credit)}</td>
                                    </tr>
                                }
                                else if(itmes.type === 'sectionTotal') {
                                    return <tr className="showTotalBorder">
                                        <td className="clmintent" style={{fontWeight: 'bold', paddingLeft: '20px'}} >{itmes.name}</td>
                                        <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated3(itmes.debit)}</td>
                                        <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated3(itmes.credit)}</td>
                                    </tr>
                                }
                                else {
                                    return <tr className={"disp_"+itmes.account_id} >
                                        <td>
                                            <div style={{paddingLeft: '20px'}}>
                                                <Link to={getDetailsLink(itmes.id)}>{itmes.name}</Link>
                                            </div>
                                        </td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated3(itmes.debit)}</Link></td>
                                        <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated3(itmes.credit)}</Link></td>
                                    </tr>
                                }
                            })
                        }
                        </tbody>
                    </table>
                </div>

            </Card>
        </>
    );
};

export default TrialBalanceV3;